<template>
  <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
    <h1 class="display-4">Smarthome-aanbiedingen.nl</h1>
    <p class="lead">Shop slim, shop bij Smarthome-aanbiedingen.nl!</p>
  </div>
  <div class="container">
    <div class="card-deck mb-3 text-center">
      <div class="card mb-4 box-shadow">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Apple HomePod Mini</h4>
        </div>
        <div class="card-body">
          <img src="https://bsimg.nl/images/apple-homepod-mini-wit_1.jpg/hbvovUO8Jk2mFQDKs8qlHBoQtnQ%3D/fit-in/365x365/filters%3Aformat%28webp%29%3Aupscale%28%29" style="width: 100%;">
          <p>
            <button type="button" class="btn btn-lg btn-block btn-outline-primary">Aanbieding bekijken</button>
          </p>
          <p class="text-center mb-0">Coolblue</p>
        </div>
      </div>
      <div class="card mb-4 box-shadow">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Apple HomePod Mini</h4>
        </div>
        <div class="card-body">
          <img src="https://bsimg.nl/images/apple-homepod-mini-wit_1.jpg/hbvovUO8Jk2mFQDKs8qlHBoQtnQ%3D/fit-in/365x365/filters%3Aformat%28webp%29%3Aupscale%28%29" style="width: 100%;">
          <p>
            <button type="button" class="btn btn-lg btn-block btn-outline-primary">Aanbieding bekijken</button>
          </p>
          <p class="text-center mb-0">Coolblue</p>
        </div>
      </div>
      <div class="card mb-4 box-shadow">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Apple HomePod Mini</h4>
        </div>
        <div class="card-body">
          <img src="https://bsimg.nl/images/apple-homepod-mini-wit_1.jpg/hbvovUO8Jk2mFQDKs8qlHBoQtnQ%3D/fit-in/365x365/filters%3Aformat%28webp%29%3Aupscale%28%29" style="width: 100%;">
          <p>
            <button type="button" class="btn btn-lg btn-block btn-outline-primary">Aanbieding bekijken</button>
          </p>
          <p class="text-center mb-0">Coolblue</p>
        </div>
      </div>
    </div>
  </div>
</template>
